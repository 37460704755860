import './App.css';
import React from 'react';
import CookieConsent from 'react-cookie-consent'
import AppRoutes from './routes'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-5DQPVSS'
};

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <div className="App">
      <header className="Matched Betting Profit Tracker">
        <AppRoutes />
        <CookieConsent>
          This website uses cookies to enhance the user experience.
      </CookieConsent>

      </header>
    </div>
  );
}

export default App;
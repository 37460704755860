import React, { Component } from 'react'
import './homepage.css'
import logo from '../../app_logo.png';
import { Link } from 'react-router-dom';
class Home extends Component {

    render() {
        return (
        <section id='home'>
            <img src={logo} className="game-logo" alt="glogo"  height="300"/>
            <h1 style={{color:'aqua'}}>
                Matched Betting Profit Tracker
            </h1>
            <p>
                <a href='https://play.google.com/store/apps/details?id=com.nepherius.mbpt&utm_source=profittracker-website&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img 
                        alt='Get it on Google Play' 
                        src='https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png' 
                        style={{ width: 150, height: 60 }}
                    />
                </a>
            </p>
            <p><Link to='/tos'>Terms of Service</Link></p>
            <p><Link to='/privacy'>Privacy Policy</Link></p>
            <p><Link to='/cookiepolicy'>Cookie Policy</Link></p>
        </section>
        )
    }
}

export default Home

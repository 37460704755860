import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './components/Home/HomePage'
import CookiePolicy from  './components/CookiePolicy/CookiePolicyPage'
import PrivacyPolicy from './components/Privacy/Privacy'
import TermsOfService from './components/TOS/TermsOfService'
import OAuthHomepage from './components/OAuthHomepage/OAuth'
import InstructionsPage from './components/Instructions/Instructions'

const AppRoutes = () => (
 <BrowserRouter>
  <Routes>
    <Route exact path="/" element={<Home/>} />
    <Route exact path="/oauth" element={<OAuthHomepage/>} />
    <Route exact path="/privacy" element={<PrivacyPolicy/>} />
    <Route exact path="/tos" element={<TermsOfService/>} />
    <Route exact path="/instructions" element={<InstructionsPage/>} />
    <Route exact path="/cookiepolicy" element={<CookiePolicy/>} />
    <Route path="/(notfound|[\s\S]*)/" element={<Home/>} />
  </Routes>
 </BrowserRouter>
)

export default AppRoutes

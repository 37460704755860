import React, { Component } from 'react'
import logo from '../../app_logo.png';
import { Link } from 'react-router-dom';

class OAuthHomepage extends Component {

    render() {
        return (
        <section id='oauth'>
            <img src={logo} className="game-logo" alt="glogo" height='300'/>
            <h1 style={{color:'aqua'}}>
            Matched Betting Profit Tracker
            </h1>
            <h4>What is Matched Betting Profit Tracker?</h4>
            <p>
                A simple matched betting profit tracker application
            </p>
            <h4>Personal Data</h4>
            <p>
                Personal data is used for registration/authentication and google-analytics. It is not shared with any other thrid-party. Please read our privacy for more information.
            </p>
            <p><Link to='/tos'>Terms of Service</Link></p>
            <p><Link to='/privacy'>Privacy Policy</Link></p>
            <p><Link to='/cookiepolicy'>Cookie Policy</Link></p>
        </section>
        )
    }
}

export default OAuthHomepage

import React, { Component } from 'react'
import logo from '../../app_logo.png';
import { Link } from 'react-router-dom';

class InstructionsPage extends Component {

    render() {
        return (
        <section id='oauth'>
            <img src={logo} className="game-logo" alt="glogo" height='300'/>
            <h1 style={{color:'aqua'}}>
            Matched Betting Profit Tracker
            </h1>
            <h4>How do I delete my personal data?</h4>
            <p>
                Follow these easy steps
            </p>
            <ol>
                <li>
                    Open the Matched Betting Profit Tracker app
                </li>
                <li>
                    Go to Settings
                </li>
                <li>
                    Tap Reset button
                </li>
                <li>
                    Tap Delete button
                </li>
            </ol>
            <p>
                Personal data is used for registration/authentication and google-analytics. It is not shared with any other thrid-party. Please read our privacy for more information.
            </p>
            <p><Link to='/tos'>Terms of Service</Link></p>
            <p><Link to='/privacy'>Privacy Policy</Link></p>
            <p><Link to='/cookiepolicy'>Cookie Policy</Link></p>
        </section>
        )
    }
}

export default InstructionsPage
